import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "/src/components/App/Layout"
import Navbar from "/src/components/App/Navbar"
import PageBanner from '/src/components/Common/PageBanner'
import Footer from "/src/components/App/Footer"
import ReactMarkdown from 'react-markdown'
import IndustryFooter from '/src/components/BlogContent/IndustryFooter'


const CaseStudiesDetailsContent = ({data}) => {
    const image = getImage(data.industry.image.localFile)
    return (
        <Layout>
            <Navbar />
            <PageBanner 
            />
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-12">
                        <div className="case-studies-details-image">
                        <GatsbyImage image={image}
                                                alt=""
                                                formats={["AUTO", "WEBP", "AVIF"]}
                                                 />
                        </div>
                        <div className="case-studies-details-desc">
                            <h2>{data.industry.title}</h2>
                            <ReactMarkdown>{data.industry.content}</ReactMarkdown>
                        </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div> */}
                </div>
            <IndustryFooter />
            </div>
        </section>
        <Footer />
        </Layout>
    )
}

export default CaseStudiesDetailsContent

export const query = graphql`
  query GetSingleIndustry($slug: String) {
    industry: strapiIndustry(slug: { eq: $slug }) {
      content
      title
      slug
      image {
        localFile {
            childImageSharp {
                gatsbyImageData(width:500)
            }
        }
      }
    }
  }
`

// image {
//   childImageSharp {
//     gatsbyImageData(width:500)
//   }
// }