import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const query = graphql`
{
    allStrapiIndustry(limit: 2) {
      nodes {
        slug
        title
        image {
            localFile {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
      }
    }
  }
`

const BlogFooter = () => {
    const data = useStaticQuery(query)
    const {
        allStrapiIndustry: { nodes: industries },
    } = data
    const image0 = getImage(industries[0].image.localFile)
    const image1 = getImage(industries[1].image.localFile)
    return (
        <div className="tracer-post-navigation">
            <div className="prev-link-wrapper">
                <div className="info-prev-link-wrapper">
                    <Link to={`/industry/${industries[0].slug}`}>
                        <span className="image-prev">
                        <GatsbyImage image={image0}
                                                alt=""
                                                formats={["AUTO", "WEBP", "AVIF"]} />
                            <span className="post-nav-title">Read More</span>
                        </span>

                        <span className="prev-link-info-wrapper">
                            <span className="prev-title">{industries[0].title}</span>
                            <span className="meta-wrapper">
                            </span>
                        </span>
                    </Link>
                </div>
            </div>

            <div className="next-link-wrapper">
                <div className="info-next-link-wrapper">
                    <Link to={`/industry/${industries[1].slug}`}>
                        <span className="next-link-info-wrapper">
                            <span className="next-title">{industries[1].title}</span>
                            <span className="meta-wrapper">
                            </span>
                        </span>

                        <span className="image-next">
                        <GatsbyImage image={image1}
                                                alt=""
                                                formats={["AUTO", "WEBP", "AVIF"]} />
                            <span className="post-nav-title">Read More</span>
                        </span>
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default BlogFooter